/** @format */

// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import zhCN from '@fullcalendar/core/locales/zh-cn'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'

// eslint-disable-next-line  no-unused-vars
export default function userCalendar(proxy) {
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)
  setTimeout(() => {
    console.log(refCalendar.value, 123)
  }, 1000)

  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  const calendarsColor = {
    Business: 'primary',
    Holiday: 'success',
    Personal: 'danger',
    Family: 'warning',
    ETC: 'info',
    undefined: 'primary',
  }

  const blankEvent = {
    title: '',
    start: '',
    end: '',
    url: '',
    img_url: '',
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, {
      allDay: updatedEventData.allDay,
    })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      url,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { calendar, guests, location, description },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      url,
      extendedProps: {
        calendar,
        guests,
        location,
        description,
      },
      allDay,
    }
  }

  const addEvent = eventData => {
    store.dispatch('calendar/addEvent', { event: eventData }).then(res => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    })
  }

  const updateEvent = eventData => {
    store.dispatch('calendar/updateEvent', { event: eventData }).then(response => {
      const updatedEvent = response.data.event
      console.log(response, 'response')

      const propsToUpdate = ['id', 'title', 'url', 'description', 'img_url']
      const extendedPropsToUpdate = ['calendar', 'guests', 'location', 'description', 'img_url']

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
  }

  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

  watch(selectedCalendars, () => {
    refetchEvents()
  })

  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    // return
    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', {
        calendars: selectedCalendars.value,
      })
      .then(response => {
        successCallback(response.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    events: fetchEvents,
    locale: zhCN,
    editable: true,
    eventResizableFromStart: true,
    dragScroll: true,
    dayMaxEvents: 3,
    navLinks: false,
    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return `bg-light-${colorName}`
    },
    eventClick({ event: clickedEvent, jsEvent }) {
      event.value = grabEventDataFromEventApi(clickedEvent)
      jsEvent.preventDefault() // 阻止当前事件触发对象的默认行为
      // 如果有url，则弹出新窗口打开
      if (event.value.url) {
        // 判断是否带有http://或者https://
        if (event.value.url.indexOf('http://') === -1 && event.value.url.indexOf('https://') === -1) {
          event.value.url = `http://${event.value.url}`
        }
        window.open(event.value.url)
      }
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    dateClick(info) {
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
      // proxy.$bvModal.show('confirm_to_url')
      // console.log(proxy);
    },

    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
    eventContent(arg) {
      let string = ''
      if (arg.event.extendedProps.img_url) {
        string = string.concat(`<img 
        src="${process.env.VUE_APP_COM_URL + arg.event.extendedProps.img_url}" 
        alt="${arg.event.title}" 
        style="width: 40px" 
        onClick="window.open('${process.env.VUE_APP_COM_URL + arg.event.extendedProps.img_url}')">`)
      }
      return {
        html: string.concat(`<div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <div class="font-weight-bold">${arg.event.title}</div>
            <div class="text-muted">${arg.event.extendedProps.description}</div>
          </div>
        </div>`),
      }
    },
  })

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
